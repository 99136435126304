import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Client } from '@shared/models/client';
import { PermissionsService } from '@core/services/permissions.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root',
})
export class EnrollmentGuard  {
    private clientsCount;

    constructor(
        private message: NzMessageService,
        private clientsService: ClientsService,
        private router: Router,
        private permissionsService: PermissionsService
    ) {
        this.clientsService.Clients.subscribe((clients: Client[]) => (this.clientsCount = clients.length));
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }

    checkActivation(): boolean {
        if (!this.clientsCount) {
            this.message.create('error', 'Adaugă cel puțin un client pentru a încărca terenuri');
            this.router.navigateByUrl('/');
            return false;
        }

        if (!this.permissionsService.canModifyFields()) {
            this.router.navigateByUrl('/acces-restrictionat');
            return false;
        }

        return true;
    }
}
