<div class="Wrapper">
    <div class="Container">
        <p class="Error-Code">Pagină inexistentă!</p>
        <p class="Error-Description">
            Pagina pe care ai încercat să intri nu mai există sau ai accesat un link invalid.
        </p>

        <div class="Home-Button-Container">
            <a class="Home-Button" routerLink="/">
                <i nz-icon nzType="home" nzTheme="outline"></i>
                <div class="Text">Mergi acasă</div></a
            >
        </div>
    </div>
</div>

<a href="/" class="Logo-Wrapper"><img src="/assets/img/logo.png" class="Logo"/></a>
