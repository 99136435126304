import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PermissionsService } from '@core/services/permissions.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalSettingsGuard  {
    constructor(private router: Router, private permissionsService: PermissionsService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }

    checkActivation(): boolean {
        const permissions: string[] = this.permissionsService.Permissions;

        if (!permissions.includes('settings.view_globalsetting')) {
            this.router.navigateByUrl('/acces-restrictionat');
            return false;
        }

        return true;
    }
}
