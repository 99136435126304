import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './components/page404/page404.component';
import { AuthGuard } from '@modules/auth/guards/auth.guard';
import { EnrollmentGuard } from '@modules/enrollment/guards/enrollment.guard';
import { PageRestrictedComponent } from './components/page-restricted/page-restricted.component';
import { GlobalSettingsGuard } from './guards/global-settings.guard';

const routes: Routes = [
    {
        path: 'clienti',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/clients/clients.module').then((m) => m.ClientsModule),
    },
    {
        path: 'harti',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('../modules/map/map.module').then((m) => m.MapModule),
    },
    {
        path: 'terenuri',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('../modules/fields/fields.module').then((m) => m.FieldsModule),
    },
    {
        path: 'documente',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/documents/documents.module').then((m) => m.DocumentsModule),
    },
    {
        path: 'sarcini',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('../modules/tasks/tasks.module').then((m) => m.TasksModule),
    },
    {
        path: 'rapoarte',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
        path: 'incarcare-terenuri',
        canActivate: [AuthGuard, EnrollmentGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/enrollment/enrollment.module').then((m) => m.EnrollmentModule),
    },
    {
        path: 'editare',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () => import('../modules/edit/edit.module').then((m) => m.EditModule),
    },
    {
        path: 'daune',
        canActivate: [AuthGuard, GlobalSettingsGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
        path: 'profil',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
            import('../modules/profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'eroare',
        component: Page404Component,
    },
    {
        path: 'acces-restrictionat',
        component: PageRestrictedComponent,
    },
    {
        path: '',
        loadChildren: () => import('../modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '**',
        redirectTo: 'eroare',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
