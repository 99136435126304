import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { Page404Component } from './components/page404/page404.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { PageRestrictedComponent } from './components/page-restricted/page-restricted.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    declarations: [Page404Component, HeaderComponent, PageRestrictedComponent],
    imports: [
        CommonModule,
        CoreRoutingModule,
        SharedModule,
        NzDividerModule,
        NzMenuModule,
        NzDropDownModule,
        NzIconModule,
        InlineSVGModule.forRoot(),
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }],
    exports: [HeaderComponent],
})
export class CoreModule {}
