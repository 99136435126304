import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'core-page-restricted',
    templateUrl: './page-restricted.component.html',
    styleUrls: ['./page-restricted.component.less']
})
export class PageRestrictedComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
