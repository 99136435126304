<div class="Header-Content" *ngIf="currentHeaderSettings.headerVisible">
    <div class="Left-Content">
        <div class="Title">{{ currentHeaderSettings.title }}</div>

        <div class="Template-Content">
            <ng-container [ngTemplateOutlet]="template"></ng-container>
        </div>
    </div>

    <div class="Right-Content">
        <div
            class="Losses-Button Soft-Hover"
            routerLink="/daune"
            *ngIf="permissions.includes('settings.view_globalsetting')"
        >
            <span nz-icon nzType="setting" nzTheme="outline"></span>
            <div class="Losses-Text">Setări rapoarte</div>
        </div>

        <div
            class="Profile-Container"
            [nzPlacement]="'bottomRight'"
            nzTrigger="hover"
            nz-dropdown
            [nzDropdownMenu]="menu"
        >
            <div class="Avatar-Container">
                <i nz-icon nzType="user" nzTheme="outline" class="User-Initials"></i>
            </div>
            <div class="User-Container">
                <span class="Name-Text">{{ user?.first_name }}{{ ' ' }}{{ user?.last_name }}</span>
                <div class="Group-Container">
                    <span class="Group-Text" *ngFor="let group of groups; let i = index">
                        <nz-divider nzType="vertical" *ngIf="i !== 0"></nz-divider>{{ group }}</span
                    >
                </div>
            </div>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable class="User-Menu">
                <li nz-menu-item routerLink="/profil">
                    <div class="flex items-center p-1">
                        <span nz-icon [nzType]="'user'"></span>
                        <div class="ml-1">Profil</div>
                    </div>
                </li>

                <li nz-menu-item (click)="logout()">
                    <div class="flex items-center p-1">
                        <span nz-icon [nzType]="'logout'"></span>
                        <div class="ml-1">Ieși din cont</div>
                    </div>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</div>
