import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { ConfigService } from '@shared/services/config.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    apiUrl: string = null;

    constructor(private authService: AuthService, private configService: ConfigService) {
        this.apiUrl = this.configService.configuration.api_uri;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headersConfig = {};

        const token = this.authService.authToken;

        if (token && req.url.includes(this.apiUrl)) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
    }
}
