import { Component, ContentChild, DestroyRef, OnInit, TemplateRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/modules/auth/services/auth.service';
import { PermissionsService } from '@core/services/permissions.service';
import { User } from '@shared/models/user';
import _ from 'lodash';

/**
 * The routes in which the header must be displayed should be written here,
 * with the attribute 'headerVisible' set to true
 */
const headerSettingsMap = [
    {
        // Must precede 'Terenuri' in order to work for the isolated polygons route
        url: 'zona',
        title: 'Zone izolate',
        headerVisible: true,
    },
    {
        url: 'terenuri',
        title: 'Terenuri',
        headerVisible: true,
    },
    {
        url: 'terenuri',
        title: 'Terenuri',
        headerVisible: true,
    },
    {
        url: 'harti',
        title: null,
        headerVisible: false,
    },
    {
        url: 'clienti',
        title: 'Clienți',
        headerVisible: true,
    },
    {
        url: 'documente',
        title: 'Documente',
        headerVisible: true,
    },
    {
        url: 'sarcini',
        title: 'Sarcini',
        headerVisible: true,
    },
    {
        url: 'rapoarte',
        title: 'Rapoarte vegetație',
        headerVisible: true,
    },
    {
        url: 'daune',
        title: 'Setări',
        headerVisible: true,
    },
    {
        url: 'profil',
        title: 'Profil',
        headerVisible: true,
    },
    {
        url: '',
        title: null,
        headerVisible: false,
    },
];

@Component({
    selector: 'core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
    destroyRef = inject(DestroyRef);

    isUiDisabled = false;
    toggleNotifications = false;

    currentHeaderSettings = {
        title: null,
        headerVisible: false,
    };

    initials = '';

    user: User;
    groups: string[] = [];

    /**
     * Permissions
     */
    permissions = [];

    @ContentChild(TemplateRef) template: TemplateRef<any>;

    constructor(
        private router: Router,
        private authService: AuthService,
        private permissionsService: PermissionsService,
    ) {}

    ngOnInit() {
        // Obtain permissions
        this.permissions = this.permissionsService.Permissions;

        this.checkRoute(this.router.url);

        this.authService.User.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (user) => {
                this.user = user;
                this.initials = _([user.first_name, user.last_name])
                    .filter(_.identity)
                    .map((name) => name[0])
                    .join('')
                    .toUpperCase();
            },
            (error) => console.error(error),
        );

        this.authService.Groups.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (groups) => (this.groups = groups),
            (error) => console.error(error),
        );
    }

    checkRoute(url: string): void {
        this.checkHeaderSettings(url);
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((navEvent) => {
            if (navEvent instanceof NavigationEnd) {
                this.checkHeaderSettings(navEvent.url);
            }
        });
    }

    checkHeaderSettings(url: string): void {
        for (const item of headerSettingsMap) {
            if (url.indexOf(item.url) > -1) {
                this.currentHeaderSettings = item;
                break;
            }
        }
    }

    /**
     * Handles logout
     */
    logout() {
        this.authService.logout();
    }
}
